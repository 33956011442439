<div class="mb-5">
  <app-navbar></app-navbar>

  <div class="mb-7" *ngIf="event">
    <div class="event-image mt-4" [style.backgroundImage]="'url('+ event.path +')'">
      <div class="image-buttons-wrapper">
        <div>
          <img class="arrow-back" src="assets/arrow-back.svg" alt="arrow back icon" (click)="goBack()">
        </div>
        <div class="event-genre mt-2 ml-2">{{event.genre}}</div>
      </div>
    </div>

    <div class="event-icons-wrapper" *ngIf="event.isAccessible || event.canHostNoisyEvents || event.petFriendly || event.hasParking || event.piano">
      <div class="d-flex event-dark-bg-image-text-box align-items-center">
        <div class="d-flex justify-content-space-evenly pr-4">
          <img *ngIf="event.isAccessible" src="assets/accessibility.svg" class="event-icon" alt="תמונת נגישות">
          <img *ngIf="event.canHostNoisyEvents" src="assets/loud.svg" class="event-icon" alt="תמונת אפשר להרעיש">
          <img *ngIf="event.petFriendly" src="assets/pets.svg" class="event-icon" alt="תמונת ידידותי לבעלי חיים">
          <img *ngIf="event.hasParking" src="assets/parking.svg" class="event-icon" alt="תמונת חנייה">
          <img *ngIf="event.piano" src="assets/piano.svg" class="event-icon" alt="תמונת פסנתר">
        </div>
      </div>
    </div>

    <div class="content-box p-4">
      <div class="d-flex mt-3 font-size-20">{{event.title}}</div>
      <div class="d-flex mt-1 font-size-16">{{event.initiativeDescription}}</div>
      <div class="d-flex mt-1 font-size-16 font-weight-500">
        <div>
          <img class="calendar-icon ml-1" src="assets/np_calendar.svg" alt="אייקון של לוח שנה">
        </div>
        <span class="mr-1">{{formattedDate}}</span>
        <span class="mr-1">יום</span>
        <span class="mr-1">{{dayOfEvent}}</span>
        <span class="mr-1">{{untilHour}}</span>
        <span class="mr-1">-</span>
        <span class="mr-1">{{fromHour}}</span>
      </div>

      <!-- event location -->
      <div class="d-flex mt-3 font-weight-bold" *ngIf="!event.virtual">
        <div class="d-flex align-items-center">
          <img class="map-pin-icon ml-1" src="assets/location.svg" alt="אייקון של מפה">
        </div>
        <div class="col-11 font-size-16">
          <!--mobile link-->
          <a class="d-block d-md-none address-link" href="google.navigation:q={{event.street}} + {{event.houseNumber}} + {{event.city}}" target="_blank">
            {{event.placeDescription}}
            רחוב
            {{event.street}}
            {{event.houseNumber}}
            כניסה
            {{event.entrance}}
            {{event.city}}
          </a>
          <!--desktop link-->
          <a class="d-none d-md-block address-link" href="http://maps.google.com/?q={{event.street}} + {{event.houseNumber}} + {{event.city}}" target="_blank">
            {{event.placeDescription}}
            רחוב
            {{event.street}}
            {{event.houseNumber}}
            כניסה
            {{event.entrance}}
            {{event.city}}
          </a>
        </div>
      </div>

      <div class="d-flex mt-3">
        <div>
          <img class="happy-icon ml-1" src="assets/public.svg" alt="icon of public event">
        </div>
        <span class="mr-1 font-size-16 font-weight-bold">{{event.public}}</span>
      </div>

      <!-- Details -->
      <div>
        <div class="d-flex mt-4 font-size-17 font-weight-bold sub-title">פירוט האירוע</div>
        <div class="d-flex mt-1 font-size-16">{{event.details}}</div>
      </div>

      <!-- Band Participants -->
      <div *ngIf="event.band.band_title">
        <div class="d-flex mt-4 font-size-17 font-weight-bold sub-title">חברי/ות הלהקה</div>
        <div class="d-flex mt-1 font-size-16">{{event.band.band_title}}</div>
        <div *ngIf="event.band.participants">
          <div class="d-flex mt-1 font-size-16" *ngFor="let participant of event.band.participants">
            <div class="ml-1">{{participant.firstName}}</div>
            <div class="ml-1">{{participant.lastName}}</div>
            <div class="ml-1">|</div>
            <div>{{participant.role}}</div>
          </div>
        </div>
      </div>

      <!-- Register -->
      <div>
        <div class="d-flex mt-4 font-size-17 font-weight-bold sub-title">הרשמה לאירוע</div>
        <div class="d-flex">
          <div [class.position-absolute]="numberOfAttendeesPercent<95">
            <span>{{event.totalNumberOfPeopleThatCanAttend}}</span>
          </div>
          <div class="w-100 direction-ltr">
            <div [style.width.%]="numberOfAttendeesPercent">
              <span *ngIf="numberOfAttendeesPercent<95">{{event.numberOfAttendees}}</span></div>
          </div>
        </div>
        <div class="limit-number-of-attendees mb-3">
          <div class="number-of-attendees" [style.width.%]="numberOfAttendeesPercent">
          </div>
        </div>

        <div class="number-of-participants-title sub-title">מספר משתתפים</div>
        <div class="number-of-participants-box">
          <div>
            <img src="assets/plus.svg" alt="plus icon" (click)="onAddParticipant()">
          </div>
          <div id="number-of-participants" class="form-control border-greyish text-center ml-3 mr-3 unselectable-text">{{numberOfRegisters}}</div>
          <div>
            <img src="assets/minus.svg" alt="minus icon" (click)="onReduceParticipant()">
          </div>
        </div>


        <div class="d-flex justify-content-center align-content-center attend-event-box mt-5 mb-5">
          <div class="attend-btn font-size-20" (click)="attendEvent()">
            <span *ngIf="!event.userIsAttendingEvent && event.numberOfAttendees<event.totalNumberOfPeopleThatCanAttend">הרשמה לאירוע</span>
            <span *ngIf="!event.userIsAttendingEvent && !event.userIsOnWaitingList && event.numberOfAttendees>=event.totalNumberOfPeopleThatCanAttend">כניסה לרשימת ההמתנה</span>

            <span *ngIf="event.userIsAttendingEvent && !event.userIsOnWaitingList">ביטול הרשמה</span>
            <span *ngIf="event.userIsAttendingEvent && event.userIsOnWaitingList && event.numberOfAttendees>event.totalNumberOfPeopleThatCanAttend">ביטול מקום ברשימת ההמתנה</span>
          </div>
        </div>
      </div>

      <!-- Notes -->
      <div *ngIf="event.placeNotes">
        <div class="d-flex mt-4 font-size-17 font-weight-bold sub-title">הוראות מיוחדות</div>
        <div class="d-flex mt-1 font-size-16">{{event.placeNotes}}</div>
      </div>

      <!-- Hosts  -->
      <div>
        <div class="d-flex mt-4 font-size-17 font-weight-bold sub-title">מארחי האירוע</div>
        <div>
          <!-- if host is initiator OR this is a virtual event -->
          <div *ngIf="(this.event.placeOwnerName===this.event.initiativeOwnerName) || this.event.virtual">
            <div class="d-flex align-items-center">
              <div>
                <span>המארח והיוזם:</span>
                <span class="mr-1">{{this.event.initiativeOwnerName}}</span>
              </div>
              <div class="col text-left">
                <a href="tel:{{event.initiativeOwnerPhone}}" target="_blank" class="ml-3">
                  <img class="phone-icon ml-1" src="assets/phone.svg" alt="אייקון של טלפון">
                </a>
                <a href="mailto:{{event.initiativeOwnerEmail}}" target="_blank">
                  <img class="email-icon ml-1" src="assets/email.svg" alt="אייקון של אימייל">
                </a>
              </div>
            </div>
          </div>

          <!-- if host is not initiator AND this is not a virtual event -->
          <div *ngIf="(this.event.placeOwnerName!==this.event.initiativeOwnerName) && !this.event.virtual">
            <div class="d-flex align-items-center">
              <div>
                <span>המארח:</span>
                <span class="mr-1">{{this.event.placeOwnerName}}</span>
              </div>
              <div class="col text-left">
                <a href="tel:{{event.placeOwnerPhone}}" target="_blank" class="ml-3">
                  <img class="phone-icon ml-1" src="assets/phone.svg" alt="אייקון של טלפון">
                </a>
                <a href="mailto:{{event.placeOwnerEmail}}" target="_blank">
                  <img class="email-icon ml-1" src="assets/email.svg" alt="אייקון של אימייל">
                </a>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div>
                <span>היוזם:</span>
                <span class="mr-1">{{this.event.initiativeOwnerName}}</span>
              </div>
              <div class="col text-left">
                <a href="tel:{{event.initiativeOwnerPhone}}" target="_blank" class="ml-3">
                  <img class="phone-icon ml-1" src="assets/phone.svg" alt="אייקון של טלפון">
                </a>
                <a href="mailto:{{event.initiativeOwnerEmail}}" target="_blank">
                  <img class="email-icon ml-1" src="assets/email.svg" alt="אייקון של אימייל">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tags  -->
      <div *ngIf="event.tags">
        <div class="d-flex mt-4 font-size-17 font-weight-bold sub-title">תגיות</div>
        <div class="d-flex flex-wrap">
          <div class="tag-box" *ngFor="let tag of event.tags">{{tag.title}}</div>
        </div>
      </div>

      <!-- Website -->
      <div class="d-flex mt-3" *ngIf="event.externalLink1">
        <a class="link-to-website" href="{{getLink(event.externalLink1)}}" target="_blank">
          <span class="mr-1">{{event.externalLinkLabel1 ? event.externalLinkLabel1 : 'קישור חיצוני'}}</span>
        </a>
      </div>

      <!-- Livestream -->
      <div class="d-flex mt-3" *ngIf="event.externalLink2">
        <div class="col-12">
          <fa-icon class="color-kinda-darker-redish" [icon]="faVideo"></fa-icon>
          <a href="{{getLink(event.externalLink2)}}" target="_blank">
            <span class="mr-1">{{event.externalLinkLabel2 ? event.externalLinkLabel2 : 'קישור חיצוני'}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer-navbar></app-footer-navbar>
